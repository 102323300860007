/* global window, utils, gon, Rollbar */

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = [ "content" ]

  initialize() {
    let target = this.contentTarget
    // let thisElement = this.element
    Rails.ajax({
      url: this.data.get('url'),
      type: "post",
      data: gon.full_active_objekt_id ? `full_active_objekt_id=${gon.full_active_objekt_id}` : '',
      success: function(data) {
        if (data.current_user) {
          gon.current_user = data.current_user
          Rollbar.configure({
            payload: {
              person: {id: data.current_user.id}
            }
          })
          target.innerHTML = data.user_menu_snippet
        }
      },
      error: function(data) {
        // nothing, forgetaboutit
      }
    })
  }

}
