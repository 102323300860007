/* global window, L, gon */

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.draw_map();
  }

  draw_map() {
    let supermarkets = JSON.parse(this.data.get('supermarkets'));
    let restaurants = JSON.parse(this.data.get('restaurants'));
    let transit_stops = JSON.parse(this.data.get('transit_stops'));

    var basemaps = {
      'Swissimage': L.tileLayer.wms('https://wms.geo.admin.ch/?', {
        layers: 'ch.swisstopo.swissimage',
        format: 'image/jpeg',
        detectRetina: true,
      }),
      [utils.t2('Swisstopo','Landeskarte')]: L.tileLayer.wms('https://wms.geo.admin.ch/?', {
        layers: 'ch.swisstopo.pixelkarte-farbe',
        format: 'image/jpeg',
        detectRetina: true,
      }),
    }
    var vicinity_map = L.map(this.element, {
      center: [gon.lat, gon.lng],
      zoom: 15, minZoom: 7, maxZoom: 18,
      scrollWheelZoom: false,
      layers: basemaps['Swissimage'],
    })
    L.control.layers(basemaps, null, {
      collapsed: false,
    }).addTo(vicinity_map);
    L.control.scale({
      metric: true, imperial: false,
      updateWhenIdle: true,
      maxWidth: 200,
    }).addTo(vicinity_map);
    L.marker([gon.lat, gon.lng], {
      icon: L.icon({
        iconUrl: gon.asset_host+"/marker-icons/marker-icon-2x-"+(gon.type=='rent' ? 'red' : 'blue')+".png",
        shadowUrl: gon.asset_host+'/marker-icons/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        // popupAnchor: [1, -34],
        shadowSize: [41, 41]
      })
    }).addTo(vicinity_map);
    L.circle([gon.lat, gon.lng], 500, {
      fill: false,
      color: '#555',
      weight: 2,
      dashArray: "4",
    }).addTo(vicinity_map);

    // restaurant logos
    restaurants.forEach(function(element) {
      L.marker([element.lat, element.lng], {
        icon: L.icon({
          iconUrl: gon.asset_host+'/logos/logo_restaurant.png',
          iconAnchor: [12,12],
        }),
        title: element.name,
      }).addTo(vicinity_map);
    });

    // supermarket logos
    supermarkets.forEach(function(element) {
      L.marker([element.lat, element.lng], {
        icon: L.icon({
          iconUrl: gon.asset_host+'/logos/supermarkets/logo_'+element.name_short+'.png',
          // iconAnchor: missing since icons have different sizes
        }),
        title: element.name,
        zIndexOffset: 1000,
      }).addTo(vicinity_map);
    });

    // transit stop logos
    transit_stops.forEach(function(element) {
      if (element.means_of_transportation) {
        // not elegant, icons sit on top of each other
        element.means_of_transportation.split('_').forEach(function(mot, i) {
          L.marker([element.lat, element.lng], {
            icon: L.icon({
              iconUrl: gon.asset_host+'/logos/transit/'+mot.toLowerCase()+'.png',
              iconAnchor: [12,12],
            }),
            title: element.name,
            zIndexOffset: 2000,
          }).addTo(vicinity_map)
        })
      }
    });

  }
}
