/* global Logger, gon, gtag */

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "isFavorite", "isNotFavorite", "card" ]

  initialize() {
    this.set_favorite_icon(this.favorite)
    if (this.visited) {
      this.mark_as_visited()
    }
    let controller = this  // so we can access it in different context
  }

  disconnect() {
    // this.channel.perform('unfollow')
  }

  toggle_favorite() {
    if (gon.current_user) {
      this.favorite = !this.favorite
      this.set_favorite_icon(this.favorite)
      Rails.ajax({
        url: this.data.get('patchUrl'),
        type: "PATCH",
        data: `favorite=${this.favorite}&dislike=false`,
        // dislike has to be false otherwise the small_view wouldn't be visible in the first place
        success: function(data) {
          // nothing, fire and forget
        }
      })
    } else {  // not logged in
      window.location = `/${gon.locale}/login`
    }
  }

  private

  set_favorite_icon(favorited) {
    if (favorited) {
      this.isFavoriteTarget.classList.remove("d-none")
      this.isNotFavoriteTarget.classList.add("d-none")
    } else {
      this.isFavoriteTarget.classList.add("d-none")
      this.isNotFavoriteTarget.classList.remove("d-none")
    }
  }

  hide_small_view() {
    // set parent node of card to hide
    this.cardTarget.parentNode.classList.add("d-none")
  }

  mark_as_visited() {
    this.cardTarget.classList.add("card-visited")
  }

  get favorite() {
    return(this.data.get('favorite') === 'true')
  }
  set favorite(favorite) {
    this.data.set('favorite', favorite.toString())
  }
  get visited() {
    return(this.data.get('visited') === 'true')
  }

}
