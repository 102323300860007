/* global window, utils, gon */

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = [ "content" ]

  initialize() {
    let target = this.contentTarget
    let thisElement = this.element
    Rails.ajax({
      url: this.data.get('url'),
      type: "get",
      success: function(data) {
        if (data.length > 1) {
          target.innerHTML = data
        } else {
          thisElement.style.display='none'
        }
      },
      error: function(data) {
        // nothing, forgetaboutit
      }
    })
  }

}
