/* global d3, gon, utils */

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.draw_chart();
  }

  draw_chart() {
    let price_comparison_data = JSON.parse(this.data.get('data'));
    var margin = {
      top: 40, right: 25, bottom: 70, left: 40,
      gap: 75  // gap between histograms
    };
    var hist_height = 70;  // histogram height

    d3.select(this.element)
      .datum(price_comparison_data)
      .call(this.priceComparisonChart());
  }

  priceComparisonChart() {
    var margin = {
      top: 40, right: 25, bottom: 70, left: 40,
      gap: 75  // gap between histograms
    };
    var hist_height = 70;  // histogram height

    function chart(selection) {
      selection.each(function(data) {

        var width = parseFloat(d3.select(this).style("width"));
        var height = hist_height*data['histograms'].length
                     + margin.gap*(data['histograms'].length-1)
                     + margin.top + margin.bottom;  // total svg height

        var svg = d3.select(this).selectAll("svg").data([data]);
        svg = svg.enter().append("svg");
        svg.enter().append("svg");
        svg.attr("width", width)
           .attr("height", height)
           .style("background-color", "#fff");
        var svgDefs = svg.append('defs');
        var mainGradient = svgDefs.append('linearGradient')
            .attr('id', 'mainGradient');
        mainGradient.append('stop')
            .attr('class', 'stop-left')
            .attr('offset', '0');
        mainGradient.append('stop')
            .attr('class', 'stop-right')
            .attr('offset', '1');

        width = width - margin.left - margin.right;
        height = height - margin.top - margin.bottom;

        var locale = d3.formatLocale({
          thousands: gon.locale=='en' ? "," : "'",
          grouping: [3], currency: ["CHF ", ""]
        })
        var formatPrice = locale.format("$,.0f");

        var x = d3.scaleLinear()
            .domain([0, data['histograms'][0]['histogram_max_price']])
            .range([0, width]);

        var this_price = data['this_price'];

        var g = svg.append("g")
                   .attr("transform",
                         "translate(" + margin.left + "," + margin.top + ")");
        // ############### line1 ############################
        g.append("line")
            .classed("guide-line", true)
              .attr("x1", x(this_price))
              .attr("y1", -margin.top)
              .attr("x2", x(this_price))
              .attr("y2", height+margin.bottom-34)
        // ############### line2 ############################
        g.append("line")
            .classed("guide-line", true)
              .attr("x1", x(this_price)-42)
              .attr("y1", height+margin.bottom-34)
              .attr("x2", x(this_price)+42)
              .attr("y2", height+margin.bottom-34)
        // ############### price display ############################
        g.append("text")
            .attr("class", "charttext")
            .attr("text-anchor", "middle")
            .attr("x", x(this_price))
            .attr("y", height+margin.bottom-10-4)
           .text(formatPrice(this_price));

        var tip = d3.tip()
                    .attr('class', 'd3-tip')
                    .offset([-10, 0])
                    .html(function(d) {
                      return d.n + utils.t2(' homes between',' Objekte zwischen') +
                             '<br/>' + formatPrice(d.x0) +
                             utils.t2(' and ',' und ') + formatPrice(d.x1);
                    });
        svg.call(tip);

        var i = 0;
        data['histograms'].forEach(function(hist_data) {
          var g = svg.append("g")
                     .attr("transform",
                           "translate(" + margin.left + "," + margin.top + ")");
          var bins = hist_data['bins'];

          var y = d3.scaleLinear()
              .domain([0, d3.max(bins, function(d) { return d.n; })])
              .range([hist_height, 0]);

          g.selectAll(".bar")
            .data(bins)
            .enter().append("g")
            .append("rect")
              .attr("x", function(d) { return x(d.x0) + 1})
              .attr("width", function(d) { return x(d.x1) - x(d.x0) - 1 })
              .attr("y", function(d) { return (y(d.n)+i*(hist_height+margin.gap))})
              .attr("height", function(d) { return hist_height - y(d.n); })
              .attr('class', function(d) {
                if (d.x1 <= hist_data.median) {
                  return 'bar bar-cheap';
                } else if (d.x0 < hist_data.median && hist_data.median < d.x1) {
                  return 'bar bar-gradient';
                } else {
                  return 'bar bar-expensive';
                }
              })
              .on('mouseover', tip.show)
              .on('mouseout', tip.hide);

          g.append("g")
              .attr("class", "axis axis--x")
              .attr("transform", "translate(0," + (hist_height+i*(hist_height+margin.gap)) + ")")
              .call(d3.axisBottom(x).ticks(Math.max(width/50)));

          g.append("g")
              .attr("class", "axis axis--y")
              .attr("transform", "translate(0," + i*(hist_height+margin.gap) + ")")
              .call(d3.axisLeft(y).ticks(3).tickSizeOuter(0));

          g.append("text")
              .attr("class", "charttext")
              .attr("text-anchor", "start")
              .attr("x", -15)
              .attr("y", i*(hist_height+margin.gap)-20)
             .text(hist_data.area_s);

        i = i+1;
        });  // each

      });
    }
    return chart;
  }


}
