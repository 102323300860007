/* eslint no-console:0 */
/* global gon, Logger */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')

// this is needed for ajax post/patch requests with CSRF-token
const Rails = require('@rails/ujs')
Rails.start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// import 'lazysizes'  // https://github.com/aFarkas/lazysizes

// $ yarn add readmore-js@next
// require('readmore-js');
// import Readmore from 'readmore-js';


// // const introJs = require('intro.js/introjs.css')
// const introJs = require('intro.js')
// introJs().start()


// Logger.useDefaults();

// // use https://codesandbox.io/s/date-fns-playground-2pmum?file=/src/index.js to play with date-fns
// // yarn add date-fns
// import { formatDistanceToNow, parseISO } from 'date-fns'
// if (typeof gon !== 'undefined') {
//   utils.log_always.info(
//     "Page rendered " +
//     formatDistanceToNow(parseISO(gon.rendered_at), {includeSeconds: true, addSuffix: true})
//   );
// }

// Logger.setLevel(gon.development ? Logger.DEBUG : Logger.ERROR);


// bootstrap in webpacker, a big fucking not understood mess
// see e.g. https://stackoverflow.com/questions/64113404/bootstrap-5-referenceerror-bootstrap-is-not-defined
// https://gist.github.com/yalab/cad361056bae02a5f45d1ace7f1d86ef

// import 'bootstrap/dist/js/bootstrap';
// import '../stylesheets/application'
// // import "bootstrap/dist/css/bootstrap";
// window.bootstrap = require('bootstrap/dist/js/bootstrap');
// // window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
// import "bootstrap/dist/css/bootstrap";

// import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
// import "bootstrap/dist/css/bootstrap";

// note: if this doesn't work with webpacker use asset pipeline
//   * bootstrap 5 already in /vendor installed
//   * uncomment in application.js, pages.js etc
//   * uncomment in application.scss
