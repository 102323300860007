/* global window, d3, bb, gon */

import { getQuarter, getYear, format } from 'date-fns'

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.draw();
    if (this.data.get('hide-panel')) {
      this.element.parentElement.classList.remove('active','show')
    }
  }

  draw() {
    let data = JSON.parse(this.data.get('data'));

    let axis_y_min, axis_y_max
    [axis_y_min, axis_y_max] = this.padded_extend(data.json)

    this.chart = bb.generate({
      bindto: this.element,
      size: {height: 300},
      data: {
        type: 'line',
        // types: {
        //   comp: "area",
        // },
        json: data['json'],
        keys: {
			  	x: "t",
				  value: ["this", "comp"],
			  },
        names: {
          'this': data['name_this'],
          'comp': data['name_comp'],
        },
      },
      title: {
        text: data['title'],
        padding: {
          bottom: 10,
        },
        position: "center"
      },
      legend: {position: 'bottom'},  // inset
      onafterinit: function() {
        // will remove 'mouseover & mouseout & click' event listeners for legend
        this.$.legend.selectAll("g").on("mouseover mouseout click", null);
	    },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: function (x) {
              return (getQuarter(x) == 1 ? getYear(x) : undefined);
            },
          },
        },
        y: {
          min: axis_y_min,
          max: axis_y_max,
          tick: {
            format: function (x) {
              return 'CHF ' + d3.format(x > 100 ? ",.0f" : ",.2f")(x);
            },
          },
        },
      },
      grid: {
        y: {show: true},
      },
      tooltip: {
        format: {
          title: function(x) {
            return format(x, 'QQQ yyyy')
          },
        },
      },
      background: this.background_missing_data(data),

    });
  }

  // normally the extend of the y axis is determined automatically
  // but if the variations in the data are small they get exegerated
  // solution: the extent of the y axis should be at least 20% of the average data or so
  padded_extend(data) {
    let min = d3.min(data, d => d3.min([d.this,d.comp]))
    let max = d3.max(data, d => d3.max([d.this,d.comp]))
    let mid = (min+max)/2
    let extent = max-min

    let f = 0.30
    if (extent/mid < f) {
      return [mid * (1 - f/2), mid * (1 + f/2)]
    } else {
      return [undefined, undefined]
    }
  }

  // set background "Data missing" if there is not enough data to display
  background_missing_data(data) {
    // console.log(data)
    var this_length = data['json'].filter(function(e) { return e['this'] != null }).length;
    if ( this_length == 0) {
      return {
        class: "insufficient-data",
        imgUrl: `/svg/insufficient_data.${gon.locale}.svg`,
      }
    } else {
      return null;
    }
  }

}
