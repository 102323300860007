/* global window, utils, gon */

import { formatDistanceToNow, parseISO } from 'date-fns'
import { de, enGB } from "date-fns/locale"

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "content" ]

  connect() {
    let target = this.contentTarget
    let created_at = parseISO(target.innerHTML.trim())
    let distance_s = formatDistanceToNow(created_at, {locale: gon.locale == 'de' ?  de : enGB, addSuffix: true})
    if (this.data.get('type') === 'short') {
      target.innerHTML = distance_s
    } else if (this.data.get('type') === 'medium') {
      target.innerHTML = utils.t2('Found ', 'Gefunden ') + distance_s
    } else {  // long
      target.innerHTML = utils.t2('Found by ImmoMapper ', 'Von ImmoMapper gefunden ') + distance_s
    }
  }

}
