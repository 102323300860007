/* global Logger, gon, gtag */

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "isFavorite", "isNotFavorite", "isDislike", "isNotDislike"  ]

  initialize() {
    this.update_buttons()
    let controller = this
    // utils.log.info('initialize')
  }

  disconnect() {
    // utils.log.info('disconnect')
  }

  update_buttons() {
    this.set_favorite_button(this.favorite)
    this.set_dislike_button(this.dislike)
  }

  toggle_favorite() {
    if (gon.current_user) {
      this.favorite = !this.favorite
      // console.log(this.favorite)
      // console.log(this.dislike)
      if (this.favorite && this.dislike) {
        this.dislike = false
      }
      this.update_buttons()
      Rails.ajax({
        url: this.data.get('patchUrl'),
        type: "PATCH",  // POST
        data: `favorite=${this.favorite}&dislike=${this.dislike}`,
        success: function(data) {
          // nothing, fire and forget
        }
      })
    } else {  // not logged in
      window.location = `/${gon.locale}/login`
    }
  }

  toggle_dislike() {
    if (gon.current_user) {
      this.dislike = !this.dislike
      if (this.dislike && this.favorite) {
        this.favorite = false
      }
      this.update_buttons()
      Rails.ajax({
        url: this.data.get('patchUrl'),
        type: "PATCH",
        data: `favorite=${this.favorite}&dislike=${this.dislike}`,
        success: function(data) {
          // nothing, fire and forget
        }
      })
    } else {  // not logged in
      window.location = `/${gon.locale}/login`
    }
  }

  private

  set_favorite_button(flag) {
    if (flag) {
      // utils.log.info('set_favorite_button true')
      this.isFavoriteTarget.classList.remove("d-none")
      this.isNotFavoriteTarget.classList.add("d-none")
    } else {
      // utils.log.info('set_favorite_button false')
      this.isFavoriteTarget.classList.add("d-none")
      this.isNotFavoriteTarget.classList.remove("d-none")
    }
  }

  set_dislike_button(flag) {
    if (flag) {
      this.isDislikeTarget.classList.remove("d-none")
      this.isNotDislikeTarget.classList.add("d-none")
    } else {
      this.isDislikeTarget.classList.add("d-none")
      this.isNotDislikeTarget.classList.remove("d-none")
    }
  }

  get favorite() {
    return(this.data.get('favorite') === 'true')
  }
  set favorite(favorite) {
    this.data.set('favorite', favorite.toString())
  }
  get dislike() {
    return(this.data.get('dislike') === 'true')
  }
  set dislike(dislike) {
    this.data.set('dislike', dislike.toString())
  }

}
