/* global window, d3, bb, utils */

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    let data = JSON.parse(this.data.get('data'));
    // console.log(data)

    var chart = bb.generate({
      bindto: this.element,
      // size: {height: 250},
      data: {
        json: data['json'],
  			keys: {
  				x: "abbr",
  				value: ["value"],
  			},
        type: "bar",
        // labels: {
        //   format: function (x) {
        //     return 'CHF ' + d3.format(x > 100 ? ",.0f" : ",.2f")(x);
        //   },
        // }
      },
      title: {
        text: data['title'],
        padding: {
          bottom: 10,
        },
        position: "center"
      },
      legend: {show: false},
      axis: {
        // rotated: true,
        x: {
          type: "category"
        },
        y: {
          tick: {
            format: function (x) {
              return d3.format(",.0f")(x);
            },
            culling: true
          }
        }
      },
      grid: {
        y: {
          show: true,
        }
      },
      tooltip: {
        show: true,
        format: {
          title: function (d) {
            return data['json'][d]['name'];
            // return 'Title ' + d;
          },
          name: function (d) {
            if (data['type'] == 'rent') {
              return utils.t2('Rent per m²', 'Miete pro m²');
            } else {
              return utils.t2('Price per m²', 'Preis pro m²');
            }
          },
          value: function (d, ratio, id, index) {
            // console.log('bla')
            // console.log(d)
            // console.log(ratio)
            // console.log(id)
            // console.log(index)
            return 'CHF ' + d3.format(d > 100 ? ",.0f" : ",.2f")(d);
          },
        }

      },
    });

  }
}
