/* global window, L, gon */

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.draw_map();
  }

  draw_map() {

    // var baseLayer =  L.tileLayer(
    //   gon.tiles_host + 'klokantech-basic/{z}/{x}/{y}.png', {
    //     minZoom: 7, maxZoom: 16,
    //   });
    var baseLayer = L.tileLayer.wms('https://wms.geo.admin.ch/?', {
			layers: 'ch.swisstopo.pixelkarte-farbe',
		// 	layers: 'ch.swisstopo.pixelkarte-grau',
			format: 'image/jpeg',
			detectRetina: true,
	  })
  //   var swissimagemap = L.tileLayer.wms('http://wms.geo.admin.ch/?', {
		// 	layers: 'ch.swisstopo.swissimage',
		// 	format: 'image/jpeg',
		// 	detectRetina: true,
	 // })

    var polygonmap = L.map(this.element, {
        scrollWheelZoom: false,
        zoomControl: false,
        dragging: false,
        touchZoom: false,
        doubleClickZoom: false,
        tap: false,
        crs: L.CRS.EPSG3857,
        worldCopyJump: false,
        tileSize: 512, zoomOffset: -1,
        layers: [baseLayer]
      }
    );
    L.control.scale({imperial: false}).addTo(polygonmap);

    polygonmap.setView([46.907, 8.585], 7);  // show CH

    let polygon = JSON.parse(this.data.get('data'));
    var polygonLayer = L.geoJson(polygon, {
      style: {
        color: 'blue',
        weight: 3,
        opacity: 0.7,
        fillColor: '#000000',
        fillOpacity: 0.2,
        "pointer-events": 'none',
        interactive: false,
      },
    });
    polygonLayer.addTo(polygonmap);
    polygonmap.fitBounds(polygonLayer.getBounds());
  }

}
