/* global window, d3, bb */

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

    let data = JSON.parse(this.data.get('data'));

    bb.generate({
      bindto: this.element,
      size: {height: 150},
      data: {
        type: 'bar',
        labels: {
          format: function (v, id, i, j) {
            return 'CHF ' + d3.format(",.0f")(v);
          },
        },
        x: 'x',
        columns: data,
        color: function (color, d) {
          var colors = ["#1e77b4", "#999999", "#444444"];
          return colors[d.index];
        },
        empty: {
          label: {
            text: "No Data Available"
          }
        },
      },
      legend: {
        show: false,
      },
      axis: {
        x: {
          type: 'category',
          // show: false,
          tick: {
            outer: false,
            count: 0,
          },
        },
        y: {
          show: false,
        },
      },
      tooltip: {
        show: false,
      },
    });
  }

}
